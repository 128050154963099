// @flow

import Storage from './Storage'
import ImageResizer from './ImageResizer'
import deviceUid from './DeviceUid'
import { log } from './log'
import type { ResizedPhoto } from './ImageResizer'
import firebase from './firebase'
import type { SelectedPhoto, StockPhoto, Photo } from './types'

class ImageUploader {
  static resizedImagePromise(
    event: SyntheticEvent<HTMLInputElement>,
  ): Promise<ResizedPhoto> {
    event.stopPropagation()
    event.preventDefault()

    return new Promise((resolve, reject) => {
      if (event.currentTarget.files.length === 0) {
        reject('noSelect')
        return
      }

      if (event.currentTarget.files.length > 1) {
        reject('multiSelect')
        return
      }

      const file = event.currentTarget.files[0]

      if (!file.type.match(/image.*/)) {
        reject('invalidFormat')
        return
      }

      resolve(ImageResizer.resize(file, 1920))
    })
  }

  static uploadStockPhoto(
    ref: string,
    photo: ResizedPhoto,
    firstName: string,
    age: string,
  ): Promise<string> {
    const dbCreator: string => StockPhoto = url => ({
      firstName,
      age: parseInt(age, 10),
      url,
      width: photo.width,
      height: photo.height,
    })
    return ImageUploader.upload(ref, photo, dbCreator)
  }

  static uploadPhoto(
    ref: string,
    photo: ResizedPhoto,
    firstName: string,
    age: string,
    code: string,
  ): Promise<string> {
    const uid = deviceUid()
    const dbCreator: string => Photo = url => ({
      firstName,
      age: parseInt(age, 10),
      url,
      width: photo.width,
      height: photo.height,
      uid,
      code,
    })
    return ImageUploader.upload(ref, photo, dbCreator)
  }

  static upload(
    ref: string,
    photo: ResizedPhoto,
    dbCreator: string => SelectedPhoto,
  ): Promise<string> {
    // Assumes associated database has same name as storage
    const database = firebase.database().ref(ref)
    const photoKey = Storage.newKey(database)

    return Storage.upload(ref, photo.data, photoKey).then(downloadURL => {
      const dbPhoto = dbCreator(downloadURL)
      return database
        .child(photoKey)
        .set(dbPhoto)
        .then(
          () => {
            return photoKey
          },
          error => {
            log('Cannot save photo attributes', photoKey, error)
            Storage.delete(ref, photoKey)
            if (error.code === 'PERMISSION_DENIED') {
              return Promise.reject('invalidCode')
            }
            return Promise.reject('onSend')
          },
        )
    })
  }
}

export default ImageUploader
