// @flow

import React from 'react'
import tr from './Translator'

import './ErrorMessage.css'

export type ErrorMsg =
  | 'noSelect'
  | 'multiSelect'
  | 'invalidFormat'
  | 'noPreview'

type Props = {
  message: ?ErrorMsg,
  onClose: () => void,
}

const ErrorMessage = ({ message, onClose }: Props) => {
  if (message == null) return null

  return (
    <div
      className="errorMessage"
      role="button"
      tabIndex="0"
      onKeyPress={onClose}
      onClick={onClose}
    >
      <div>
        <button className="errorMessageCloseButton" type="button">
          ✕
        </button>
        {tr().error[message]}
      </div>
    </div>
  )
}

export default ErrorMessage
