// @flow

import React, { Component } from 'react'
import { setLocale, getLocale } from './Translator'
import type { Locale } from './Translator'

import frFlag from './icons/fr.svg'
import enFlag from './icons/gb.svg'

const flags: { [Locale]: string } = { fr: frFlag, en: enFlag }

type Props = {
  locale: Locale,
  onLanguageChanged: () => void,
}

class LanguageSwitcher extends Component<Props> {
  render() {
    return (
      <div
        onClick={() => this.onLanguageChanged(this.props.locale)}
        onKeyPress={() => this.onLanguageChanged(this.props.locale)}
        role="button"
        tabIndex="0"
        className={`languageSwitcher${
          this.props.locale === getLocale() ? ' currentLocale' : ''
        }`}
      >
        <img src={flags[this.props.locale]} alt={this.props.locale} />
      </div>
    )
  }

  onLanguageChanged(locale: Locale) {
    setLocale(locale)
    this.props.onLanguageChanged()
  }
}

export default LanguageSwitcher
