// @flow

// This code is adapted from
// http://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side

import { log } from './log'

const DEFAULT_ORIENTATION = 1

export default function getImageOrientation(file: Blob): Promise<number> {
  return new Promise(resolve => {
    try {
      if (typeof FileReader === 'undefined') {
        resolve(DEFAULT_ORIENTATION)
        return
      }

      const reader = new FileReader()

      reader.onerror = () => {
        reader.abort()
        resolve(DEFAULT_ORIENTATION)
      }

      reader.onload = evt => {
        const view = new DataView(evt.target.result)
        if (view.getUint16(0, false) !== 0xffd8) {
          resolve(DEFAULT_ORIENTATION) // Not a JPEG
          return
        }

        const length = view.byteLength
        let offset = 2
        while (offset < length) {
          const marker = view.getUint16(offset, false)
          offset += 2
          if (marker === 0xffe1) {
            const value = view.getUint32((offset += 2), false)
            if (value !== 0x45786966) {
              resolve(DEFAULT_ORIENTATION) // Incorrect EXIF data
              return
            }
            const little = view.getUint16((offset += 6), false) === 0x4949
            offset += view.getUint32(offset + 4, little)
            const tags = view.getUint16(offset, little)
            offset += 2
            for (let i = 0; i < tags; i++) {
              const value2 = view.getUint16(offset + i * 12, little)
              if (value2 === 0x0112) {
                resolve(view.getUint16(offset + i * 12 + 8, little))
                return
              }
            }
            // eslint-disable-next-line no-bitwise
          } else if ((marker & 0xff00) !== 0xff00) break
          else offset += view.getUint16(offset, false)
        }

        resolve(DEFAULT_ORIENTATION) // No EXIF found
      }

      reader.readAsArrayBuffer(file)
    } catch (error) {
      log('Error in ImageOrientation', error)
      resolve(DEFAULT_ORIENTATION) // Swallow error
    }
  })
}
