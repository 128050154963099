// @flow

import firebase from './firebase'

// Initialize Firebase
const firebaseStagingConfig = {
  apiKey: 'AIzaSyCsXVYBBFC6iyjdTPfRJx-UPDs6l4WtHEc',
  authDomain: 'futuroscope-staging.firebaseapp.com',
  databaseURL: 'https://futuroscope-staging.firebaseio.com',
  projectId: 'futuroscope-staging',
  storageBucket: 'futuroscope-staging.appspot.com',
}

const firebaseConfig = {
  apiKey: 'AIzaSyAEvc0Kf3a0f__kJs_7xHPnk1hJX8bjrQg',
  authDomain: 'futuroscope-7b61e.firebaseapp.com',
  databaseURL: 'https://futuroscope-7b61e.firebaseio.com',
  projectId: 'futuroscope-7b61e',
  storageBucket: 'futuroscope-7b61e',
}

// Run `firebase use [staging | default]` accordingly
const IS_STAGING = process.env.REACT_APP_STAGING === 'true'

// eslint-disable-next-line no-console
if (IS_STAGING) console.log('--- STAGING DATABASE ---')

firebase.initializeApp(IS_STAGING ? firebaseStagingConfig : firebaseConfig)
