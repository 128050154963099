// @flow

import React from 'react'
import Loader from './Loader'
import Photo from './Photo'

export type Preview = {
  url: string,
  width: number,
  height: number,
}

type Props = {
  preview: ?Preview,
  // eslint-disable-next-line react/require-default-props
  onImageChanged: (SyntheticEvent<HTMLInputElement>) => void, // ! Actually optionnal
}

const UploadPreview = (props: Props) => (
  <div style={{ position: 'relative' }}>
    {props.preview ? (
      <div>
        <label className="file-upload withBorder" htmlFor="imageInput">
          <input
            type="file"
            id="imageInput"
            accept="image/*"
            capture="camera"
            onChange={e => props.onImageChanged(e)}
          />
          <Photo
            src={props.preview.url}
            srcWidth={props.preview.width}
            srcHeight={props.preview.height}
            height={200}
          />
        </label>
      </div>
    ) : (
      <Loader />
    )}
  </div>
)

UploadPreview.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  onImageChanged: () => {},
}

export default UploadPreview
