// @flow

import React from 'react'
import tr from './Translator'

import './FormErrorMessage.css'

export type FromErrorMsg = 'firstName' | 'age' | 'code' | 'acceptedConditions'

type Props = {
  message: ?FromErrorMsg,
}

const FormErrorMessage = ({ message }: Props) => {
  if (message == null) return null

  return <div className="formErrorMessage">{tr().invalid[message]}</div>
}

export default FormErrorMessage
