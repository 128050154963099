// @flow

import Utils from './Utils'
import AbstractSynchronizer from './AbstractSynchronizer'
import type { StateSynchronizer } from './AbstractSynchronizer'
import type { Photo, StockPhoto, SelectedPhoto } from './types'

export const PHOTOS = 'photos'
export const STOCK_PHOTOS = 'stockPhotos'
export const ACCOMPLICE_PHOTOS = 'accomplicePhotos'
export const BANNERS = 'banners'
export const SELECTED_PHOTOS = 'selectedPhotos'

export type BannersSynchronizedState = {
  banners: { [key: string]: string },
}

export type StockPhotosSynchronizedState = {
  stockPhotos: { [key: string]: StockPhoto },
}

export type AccomplicePhotosSynchronizedState = {
  accomplicePhotos: { [key: string]: StockPhoto },
}

export type SelectedPhotosSynchronizedState = {
  selectedPhotos: { [key: string]: SelectedPhoto },
}

export type PhotosDict = {
  [key: string]: Photo,
}

export type PhotosSynchronizedState = {
  photos: PhotosDict,
}

class ObjectSynchronizer extends AbstractSynchronizer
  implements StateSynchronizer {
  newStateWithChildAdded(previousState: Object, key: string, value: mixed) {
    return {
      [this.field]: Utils.objectWithKeyValue(
        previousState[this.field],
        key,
        value,
      ),
    }
  }

  newStateWithChildRemoved(previousState: Object, key: string) {
    return {
      [this.field]: Utils.objectWithoutKey(previousState[this.field], key),
    }
  }
}

export default ObjectSynchronizer
