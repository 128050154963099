// @flow

import { log } from './log'
import firebase from './firebase'

class Auth {
  static login(
    email: string,
    password: string,
    // eslint-disable-next-line no-undef
  ): Promise<$npm$firebase$auth$UserCredential> {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(user => {
          resolve(user)
        })
        .catch(error => {
          log('User authentication error', email, error.code, error.message)
          reject(error.code)
        })
    })
  }

  static logout() {
    firebase
      .auth()
      .signOut()
      .catch(error => {
        log('Unable to logout user', error)
      })
  }
}

export default Auth
