// @flow

const CHARS = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

const LENGTH = 36
const KEY = 'uid'

function getRandomId(randomizer) {
  return Array(LENGTH)
    .join()
    .split(',')
    .map(() => {
      return CHARS.charAt(Math.floor(randomizer() * CHARS.length))
    })
    .join('')
}

function getCookie() {
  const cookies = document.cookie
    .split(';')
    .filter(s => s.substring(0, KEY.length) === KEY)
  return cookies.length > 0 ? cookies[0].slice(KEY.length + 1) : null
}

function setCookie(cookie) {
  document.cookie = `${KEY}=${cookie};expires=${new Date(
    2030,
    12,
  ).toUTCString()}`
}

export function resetDeviceUidForBatch() {
  document.cookie = `${KEY}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export default (randomizer: () => number = Math.random) => {
  let cookie = getCookie()
  if (cookie === null) {
    cookie = getRandomId(randomizer)
    setCookie(cookie)
  }
  return cookie
}
