// @flow

// Firebase config initialized first so that log can work
import './firebaseInit'
import './eventWatcher'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './AppMobile'

import './style/normalize.css'

const root = document.getElementById('root')
if (root) ReactDOM.render(<App />, root)
