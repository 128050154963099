// @flow

import React, { Component } from 'react'
import Welcome from './Welcome'
import UploadPreview from './UploadPreview'
import type { Preview } from './UploadPreview'
import ErrorMessage from './ErrorMessage'
import type { ErrorMsg } from './ErrorMessage'
import ImageUploader from './ImageUploader'
import type { ResizedPhoto } from './ImageResizer'
import { PHOTOS } from './ObjectSynchronizer'
import MobileForm from './MobileForm'
import type { FormSubmittedCallback } from './MobileForm'
import Modal from './Modal'
import Auth from './Auth'
import tr, { getLocale } from './Translator'
import URL from './url'

import logoFuturoscope from './images/logoFuturoscope.svg'
import thanksFr from './images/thanks.fr.svg'
import thanksEn from './images/thanks.en.svg'

import './style/normalize.css'
import './AppMobile.css'

import type { Locale } from './Translator'

const thanks: { [Locale]: string } = { fr: thanksFr, en: thanksEn }

type State = {
  currentPage: 'WELCOME' | 'FORM' | 'THANKS',
  errorMessage: ?ErrorMsg,
  uploadInProgress: boolean,
  preview: ?Preview,
  showPreview: boolean,
}

class App extends Component<{}, State> {
  resizedPhotoPromise: Promise<ResizedPhoto>

  constructor(props: {}) {
    super(props)
    this.state = {
      currentPage: 'WELCOME',
      errorMessage: null,
      uploadInProgress: false,
      preview: undefined,
      showPreview: false,
    }

    Auth.logout()
    this.handleFormSubmitted = this.handleFormSubmitted.bind(this)
  }

  render() {
    const welcomePage = (
      <Welcome onImageChanged={e => this.handleImageChanged(e)} />
    )

    const formPage = (
      <form className="mobileForm">
        <div className="row">
          <div className="fullWidthRow photoPreview">
            {this.state.showPreview && (
              <UploadPreview
                preview={this.state.preview}
                onImageChanged={e => this.handleImageChanged(e)}
              />
            )}
          </div>
        </div>
        <MobileForm
          uploadInProgress={this.state.uploadInProgress}
          onFormSubmitted={this.handleFormSubmitted}
        />
        <Modal id="modalGtu">
          <h3 className="GtuTitle">{tr().GTUTitle}</h3>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: tr().GTUContent }} />
        </Modal>
      </form>
    )

    const thanksPage = (
      <div className="thanksPage">
        <img
          className="uploadSuccessfull"
          src={thanks[getLocale()]}
          alt="success"
        />
        <div className="thanksMessage">
          <div>{tr().thanks.line1}</div>
          <div>{tr().thanks.line2}</div>
          <div>{tr().thanks.line3}</div>
        </div>
      </div>
    )

    let currentPage
    if (this.state.currentPage === 'WELCOME') currentPage = welcomePage
    else if (this.state.currentPage === 'FORM') currentPage = formPage
    else currentPage = thanksPage

    return (
      <div className="container content">
        {currentPage}
        <ErrorMessage
          message={this.state.errorMessage}
          onClose={() => this.handleErrorMessageClosed()}
        />
        {this.state.currentPage !== 'THANKS' && (
          <img
            className="logoFuturoscope"
            src={logoFuturoscope}
            alt="futuroscope"
          />
        )}
      </div>
    )
  }

  handleImageChanged(event: SyntheticEvent<HTMLInputElement>) {
    this.setState({
      preview: undefined,
      currentPage: 'FORM',
      showPreview: true,
    })

    this.resizedPhotoPromise = ImageUploader.resizedImagePromise(event)

    this.resizedPhotoPromise
      .then(photo => {
        this.setState({
          preview: {
            url: URL.createObjectURL(photo.data),
            width: photo.width,
            height: photo.height,
          },
        })
      })
      .catch(errorMessage => {
        this.setState({ errorMessage })
      })
  }

  handleErrorMessageClosed() {
    this.setState({ errorMessage: null })
  }

  handleFormSubmitted: FormSubmittedCallback

  handleFormSubmitted(firstName: string, age: string, code: string) {
    if (!this.state.preview) {
      this.setState({ errorMessage: 'noPreview' })
      return
    }

    this.setState({ uploadInProgress: true, errorMessage: null })

    this.resizedPhotoPromise
      .then(photo => {
        return ImageUploader.uploadPhoto(
          PHOTOS,
          photo,
          firstName,
          age,
          code,
        ).then(() => {
          this.setState({
            uploadInProgress: false,
            preview: undefined,
            showPreview: false,
            currentPage: 'THANKS',
          })
        })
      })
      .catch(errorMessage => {
        this.setState({ uploadInProgress: false, errorMessage })
      })
  }
}

export default App
