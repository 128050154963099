// @flow

import { log } from './log'
import Storage from './Storage'
import { CONFIG } from './ConfigSynchronizer'
import { PHOTOS, SELECTED_PHOTOS } from './ObjectSynchronizer'
import type { PhotosDict } from './ObjectSynchronizer'
import {
  VALID_KEYS,
  PICKED_KEYS,
  UNSELECTED_KEYS,
  BANNED_UIDS,
} from './KeyListSynchronizer'
import firebase from './firebase'

export const SESSION_CODE_LENGTH = 3
export const MINIMUM_PHOTO_COUNT = 2

class SessionManager {
  static generateRandomSessionCode() {
    // const s = "abcdefghijklmnpqrstuvwxyz0123456789"
    const s = '0123456789'
    return Array(SESSION_CODE_LENGTH)
      .join()
      .split(',')
      .map(() => {
        return s.charAt(Math.floor(Math.random() * s.length))
      })
      .join('')
  }

  static startSession(selectedKeys: string[], photos: PhotosDict) {
    // debug('START SESSION', Object.keys(photos).length)
    return SessionManager.changeSessionCode().then(() => {
      return SessionManager.saveSelectedKeys(selectedKeys, photos).then(() => {
        return SessionManager.deleteUnselectedPhotos(selectedKeys, photos)
          .then(() => {
            return SessionManager.clearAllSessionData()
          })
          .catch(() => {
            log('Error, in startSession catch')
            // Swallow error, so that session can start anyway
            return SessionManager.clearAllSessionData()
          })
      })
    })
  }

  static endSession(selectedKeys: string[]) {
    // debug('END SESSION', selectedKeys.toString())
    return SessionManager.clearDatabase(PICKED_KEYS).then(() => {
      return SessionManager.clearDatabase(SELECTED_PHOTOS).then(() => {
        return SessionManager.deletePhotosFromStorage(selectedKeys)
      })
    })
  }

  static saveSelectedKeys(selectedKeys: string[], photos: PhotosDict) {
    // debug('Copy to selectedPhotos', selectedKeys.length)
    const updates = {}
    let allPhotosSynced = true
    selectedKeys.forEach(key => {
      const photo = photos[key]
      if (photo) {
        updates[key] = {
          firstName: photo.firstName,
          url: photo.url,
          width: photo.width,
          height: photo.height,
        }
      } else allPhotosSynced = false
    })

    if (!allPhotosSynced) {
      log('SessionManager, not all photos are available')
      return Promise.reject('Erreur avec les données des photos sélectionnées')
    }

    return firebase
      .database()
      .ref(SELECTED_PHOTOS)
      .update(updates)
      .catch(error => {
        log('SessionManager, Unable to update all selectedPhotos', error)
        return Promise.reject(
          "Erreur lors de l'enregistrement des photos sélectionnées",
        )
      })
  }

  static deleteUnselectedPhotos(selectedKeys: string[], photos: PhotosDict) {
    const photoKeys = Object.keys(photos)
    const nonSelectedKeys = photoKeys.filter(key => !selectedKeys.includes(key))
    return SessionManager.deletePhotosFromStorage(nonSelectedKeys)
  }

  static deletePhotosFromStorage(keys: string[]) {
    // debug('Delete from storage', keys.length, keys.toString())
    const photoDeletes = keys.map(
      key => new Promise(resolve => resolve(Storage.delete(PHOTOS, key))),
    )
    return Promise.all(photoDeletes).catch(error => {
      log('SessionManager, Unable to delete photos', error)
      return Promise.reject('Erreur lors de la suppression des photos')
    })
  }

  static clearAllSessionData() {
    return Promise.all([
      SessionManager.clearDatabase(PHOTOS),
      SessionManager.clearDatabase(VALID_KEYS),
      SessionManager.clearDatabase(UNSELECTED_KEYS),
      SessionManager.clearDatabase(PICKED_KEYS),
      SessionManager.clearDatabase(BANNED_UIDS),
    ])
  }

  static clearDatabase(ref: string) {
    // debug('Clear database', ref)
    return firebase
      .database()
      .ref(ref)
      .remove()
      .catch(error => {
        log(`SessionManager, Unable to delete ${ref}`, error)
        return Promise.reject(`Erreur lors de la suppression de ${ref}`)
      })
  }

  static changeSessionCode() {
    const firebaseCodeRef = firebase
      .database()
      .ref(CONFIG)
      .child('code')
    const newCode = this.generateRandomSessionCode()
    // debug('SessionManager, change code', newCode)
    return firebaseCodeRef.set(newCode).catch(error => {
      log('SessionManager, Unable to update code in database', error, newCode)
      return Promise.reject('Impossible de modifier le code séance')
    })
  }
}

export default SessionManager
