// @flow

import React, { Component } from 'react'
import FormErrorMessage from './FormErrorMessage'
import type { FromErrorMsg } from './FormErrorMessage'
import { SESSION_CODE_LENGTH } from './SessionManager'
import LanguageSwitcher from './LanguageSwitcher'
import tr from './Translator'

import './MobileForm.css'

export type FormSubmittedCallback = (
  firstName: string,
  age: string,
  code: string,
) => void

type Props = {
  uploadInProgress: boolean,
  onFormSubmitted: FormSubmittedCallback,
}

type Validator<T> = {
  blurred: boolean,
  validate: (?boolean) => boolean,
  onBlur: () => void,
  onChange: T => void,
}

type State = {
  firstName: string,
  age: string,
  code: string,
  acceptedConditions: boolean,
  firstNameErrorMessage: ?FromErrorMsg,
  ageErrorMessage: ?FromErrorMsg,
  codeErrorMessage: ?FromErrorMsg,
  acceptedConditionsErrorMessage: ?FromErrorMsg,
}

class MobileForm extends Component<Props, State> {
  form: {
    firstName: Validator<string>,
    age: Validator<string>,
    code: Validator<string>,
    acceptedConditions: Validator<boolean>,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      firstName: '',
      age: '',
      code: '',
      acceptedConditions: false,
      firstNameErrorMessage: null,
      ageErrorMessage: null,
      codeErrorMessage: null,
      acceptedConditionsErrorMessage: null,
    }

    this.form = {
      firstName: this.buildValidator(
        'firstName',
        v => v != null && v.length > 1,
      ),
      age: this.buildValidator('age', v => v !== ''),
      code: this.buildValidator(
        'code',
        v => v != null && v.length === SESSION_CODE_LENGTH,
      ),
      acceptedConditions: this.buildValidator('acceptedConditions', v => v),
    }
  }

  onSubmit(e: SyntheticEvent<HTMLDivElement>) {
    if (!this.props.uploadInProgress) this.onFormSubmitted(e)
  }

  render() {
    return (
      <div>
        <div>
          <label htmlFor="firstNameInput">
            {this.state.firstNameErrorMessage !== null ? (
              <FormErrorMessage message={this.state.firstNameErrorMessage} />
            ) : (
              tr().firstName
            )}
          </label>
        </div>
        <div>
          <input
            id="firstNameInput"
            className={this.state.firstNameErrorMessage !== null ? 'error' : ''}
            type="text"
            value={this.state.firstName}
            onChange={e => this.form.firstName.onChange(e.target.value)}
            onBlur={() => this.form.firstName.onBlur()}
          />
        </div>
        <div>
          <label htmlFor="ageInput">
            {this.state.ageErrorMessage !== null ? (
              <FormErrorMessage message={this.state.ageErrorMessage} />
            ) : (
              tr().age
            )}
          </label>
        </div>
        <div>
          <select
            id="ageInput"
            className={this.state.ageErrorMessage !== null ? 'error' : ''}
            value={this.state.age}
            onChange={e => this.form.age.onChange(e.target.value)}
            onBlur={() => this.form.age.onBlur()}
          >
            <option value="" />
            {[...Array(76)].map((x, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <option key={i} value={i + 2}>
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  {i + 2} {tr().yearOld}
                </option>
              )
            })}
          </select>
        </div>
        <div>
          <label htmlFor="codeInput">
            {this.state.codeErrorMessage !== null ? (
              <FormErrorMessage message={this.state.codeErrorMessage} />
            ) : (
              tr().showCode
            )}
          </label>
        </div>
        <div>
          <input
            id="codeInput"
            className={this.state.codeErrorMessage !== null ? 'error' : ''}
            type="number"
            pattern="\d*"
            value={this.state.code}
            maxLength={SESSION_CODE_LENGTH}
            placeholder=". . ."
            onChange={e => this.form.code.onChange(e.target.value)}
            onBlur={() => this.form.code.onBlur()}
          />
        </div>
        <div>
          <FormErrorMessage
            message={this.state.acceptedConditionsErrorMessage}
          />
          <input
            id="gtuInput"
            type="checkbox"
            checked={this.state.acceptedConditions}
            onChange={e =>
              this.form.acceptedConditions.onChange(e.target.checked)
            }
            onBlur={() => this.form.acceptedConditions.onBlur()}
          />
          <label
            htmlFor="gtuInput"
            className={`labelCGU${
              this.state.acceptedConditionsErrorMessage !== null ? ' error' : ''
            }`}
          >
            <span />
            {tr().ReadAndAccept}
            &nbsp;
            <a href="#modalGtu">{tr().GTU}</a>
          </label>
        </div>
        {this.props.uploadInProgress && (
          <div className="uploadInProgress">{tr().UploadInProgress}</div>
        )}
        <div className="fullWidthRow sendRow">
          <LanguageSwitcher
            locale="fr"
            onLanguageChanged={() => this.forceUpdate()}
          />
          <div
            className={`formButton ${
              this.props.uploadInProgress ? ' disabled' : ''
            }`}
            role="button"
            tabIndex="0"
            onKeyPress={e => this.onSubmit(e)}
            onClick={e => this.onSubmit(e)}
          >
            {tr().send}
          </div>
          <LanguageSwitcher
            locale="en"
            onLanguageChanged={() => this.forceUpdate()}
          />
        </div>
      </div>
    )
  }

  buildValidator<T>(name: string, isValid: T => boolean): Validator<T> {
    const result: Validator<T> = {
      blurred: false,
      validate: () => false,
      onChange: () => {},
      onBlur: () => {},
    }

    result.validate = onSubmit => {
      const valid = isValid(this.state[name])
      this.setState({
        [`${name}ErrorMessage`]: valid ? null : name,
      })
      if (onSubmit === true) result.blurred = true
      return valid
    }

    result.onChange = (value: T) => {
      this.setState({ [name]: value }, () => {
        if (result.blurred) result.validate()
      })
    }

    result.onBlur = () => {
      result.blurred = true
      result.validate()
    }

    return result
  }

  validateForm() {
    const conditions = [
      this.form.firstName.validate(true),
      this.form.age.validate(true),
      this.form.code.validate(true),
      this.form.acceptedConditions.validate(true),
    ]

    return conditions.every(condition => condition)
  }

  onFormSubmitted(event: SyntheticEvent<HTMLDivElement>) {
    event.stopPropagation()
    event.preventDefault()

    if (!this.validateForm()) return

    this.props.onFormSubmitted(
      this.state.firstName,
      this.state.age,
      this.state.code,
    )
  }
}

export default MobileForm
