// @flow

import { log } from './log'
import canvasToBlobPolyfill from './canvas-to-blob'
import getImageOrientation from './ImageOrientation'
import testImageF from './images/orientation.jpg'

// Transform code comes from
// http://stackoverflow.com/questions/20600800/js-client-side-exif-orientation-rotate-and-mirror-jpeg-images

canvasToBlobPolyfill(window)

function isImageOrientationSupported(): Promise<boolean> {
  return new Promise(resolve => {
    const img = new Image()

    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      canvas.width = 1
      canvas.height = 1
      ctx.drawImage(img, 0, 0)
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)

      resolve(imageData.data[0] < 128)
    }

    img.src = testImageF
  })
}

const ImageResizerIsSupported =
  typeof HTMLCanvasElement !== 'undefined' &&
  (typeof FileReader !== 'undefined' || typeof URL !== 'undefined')

export type ResizedPhoto = {
  data: Blob,
  width: number,
  height: number,
}

export default class ImageResizer {
  static resize(file: File, maxDimension: number): Promise<ResizedPhoto> {
    if (!ImageResizerIsSupported) log('ImageResizer is not supported')

    return new Promise(async function(resolve, reject) {
      try {
        const orientationSupported = await isImageOrientationSupported()

        let orientation = 1
        if (!orientationSupported) orientation = await getImageOrientation(file)

        const image = document.createElement('img')

        image.onload = () => {
          const { width, height } = image

          if (!ImageResizerIsSupported) {
            resolve({ data: file, width, height })
            return
          }

          const canvas = document.createElement('canvas')
          const ratio = Math.min(
            1.0,
            maxDimension / width,
            maxDimension / height,
          )

          let newWidth
          let newHeight
          if ([5, 6, 7, 8].indexOf(orientation) >= 0) {
            // Mind the swap
            newWidth = Math.round(ratio * height)
            newHeight = Math.round(ratio * width)
          } else {
            newWidth = Math.round(ratio * width)
            newHeight = Math.round(ratio * height)
          }

          canvas.width = newWidth
          canvas.height = newHeight

          const ctx = canvas.getContext('2d')

          if (!ctx) {
            resolve({ data: file, width, height })
            return
          }

          ctx.scale(ratio, ratio)

          switch (orientation) {
            case 2:
              ctx.transform(-1, 0, 0, 1, width, 0)
              break
            case 3:
              ctx.transform(-1, 0, 0, -1, width, height)
              break
            case 4:
              ctx.transform(1, 0, 0, -1, 0, height)
              break
            case 5:
              ctx.transform(0, 1, 1, 0, 0, 0)
              break
            case 6:
              ctx.transform(0, 1, -1, 0, height, 0)
              break
            case 7:
              ctx.transform(0, -1, -1, 0, height, width)
              break
            case 8:
              ctx.transform(0, -1, 1, 0, 0, width)
              break
            default:
              ctx.transform(1, 0, 0, 1, 0, 0)
          }

          // draw image
          ctx.drawImage(image, 0, 0)

          canvas.toBlob(
            blob => {
              resolve({ data: blob, width: newWidth, height: newHeight })
            },
            'image/jpeg',
            0.9,
          )
        }

        ImageResizer.loadImage(image, file)
      } catch (error) {
        log('Catch in ImageResizer', error)
        reject('imageResizer')
      }
    })
  }

  static loadImage(image: HTMLImageElement, file: File) {
    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader()
      reader.onload = evt => {
        // eslint-disable-next-line no-param-reassign
        image.src = evt.target.result
      }
      reader.readAsDataURL(file)
    } else {
      // eslint-disable-next-line no-param-reassign
      image.src = URL.createObjectURL(file)
    }
  }
}
