// @flow

export type Locale = 'en' | 'fr'

type Dict = {|
  introduction: {|
    line1: string,
    line2: string,
    line3: string,
  |},
  TakePicture: string,
  UploadInProgress: string,
  thanks: {|
    line1: string,
    line2: string,
    line3: string,
  |},
  firstName: string,
  age: string,
  yearOld: string,
  showCode: string,
  ReadAndAccept: string,
  GTU: string,
  send: string,
  error: {|
    noPreview: string,
    imageResizer: string,
    onSend: string,
    invalidFormat: string,
    invalidCode: string,
    noSelect: string,
    multiSelect: string,
    onUpload: string,
  |},
  invalid: {|
    firstName: string,
    age: string,
    code: string,
    acceptedConditions: string,
  |},
  'auth/in-progress': string,
  'auth/invalid-email': string,
  'auth/user-disabled': string,
  'auth/user-not-found': string,
  'auth/wrong-password': string,
  GTUTitle: string,
  GTUContent: string,
|}

const fr: Dict = {
  introduction: {
    line1: 'Préparez votre plus belle grimace',
    line2: 'Prenez-la en photo',
    line3: 'Envoyez-la !',
  },
  TakePicture: 'Prendre une photo',
  UploadInProgress: 'Transfert en cours...',
  thanks: {
    line1: 'Merci',
    line2: 'de votre',
    line3: 'participation !',
  },
  firstName: 'Prénom',
  age: 'Âge',
  yearOld: 'ans',
  showCode: 'Code de la séance',
  ReadAndAccept: "J'ai lu et j'accepte les",
  GTU: 'CGU',
  send: 'Envoyer',
  error: {
    noPreview: "La photo n'est pas chargée",
    imageResizer: 'Erreur lors du traitement de la photo',
    onSend: "Erreur lors de l'envoi",
    invalidFormat: 'Format du fichier photo incorrect',
    invalidCode: 'Code séance invalide',
    noSelect: 'Pas de photo sélectionnée',
    multiSelect: "Merci de ne sélectionner qu'une seule photo",
    onUpload: 'Erreur lors du transfert de la photo',
  },
  invalid: {
    firstName: "Quel est le prénom de l'enfant ?",
    age: "Quel est l'âge de l'enfant ?",
    code: 'Recopiez le code affiché sur les écrans',
    acceptedConditions: "Autorisez l'utilisation de cette photo",
  },
  'auth/in-progress': 'Connexion en cours...',
  'auth/invalid-email': 'Email invalide',
  'auth/user-disabled': 'Utilisateur désactivé',
  'auth/user-not-found': 'Utilisateur non trouvé',
  'auth/wrong-password': 'Code séance invalide',
  GTUTitle: 'MENTIONS LÉGALES',
  GTUContent: `
<p>
<b>PRÉAMBULE</b>
Les photos envoyées ne sont pas conservées. Elles sont automatiquement supprimées après chaque séance !
</p>
<p>
<b>Éditeur</b>
Société MAGIEVASION PRODUCTIONS, SARL,
au capital de 7622 €,
dont le siège social est situé
37 rue Jacques Forestier
La Gaubretière (85130),
Tél : 06 88 68 66 90
inscrite au Registre du Commerce et des Sociétés de La Roche-Sur-Yon sous le N° 392 615 845,
n° de TVA Intracommunautaire FR 21 392615845
Courriel : magievasion@orange.fr
Directeur de la publication
Monsieur Bertran LOTTH, qualité d’artiste illusionniste
</p>

<p>
<b>Hébergement</b>
Google – Saint Ghislain, Belgique.
</p>

<p>
<b>Conception, création et développements</b>
Blue Source,
23 quai de Bondy à Lyon (69005). Courriel : contact@bluesource.fr
</p>

<p>
<b>Données personnelles</b>
Les informations que l’utilisateur communique sur le site permettent à la société MAGIEVASION, de traiter et sélectionner des photos de grimaces d’enfants venus assister au spectacle ILLUSIO présenté au Parc du Futuroscope a n de participer au spectacle. Conformément à l’article 32 de la Loi Informatique et Libertés du 6 janvier 1978, les informations indispensables au traitement et
à l’exécution des demandes sont signalées par un astérisque. Les autres informations demandées, pour lesquelles la réponse est facultative, sont destinées à mieux connaître les utilisateurs et ainsi à améliorer les services qui leur sont proposés. Conformément à la Loi Informatique et Libertés, l’utilisateur dispose d’un droit d’accès, de modi cation, de recti cation et de suppression des données qui le concernent (articles 39 et 40 de la Loi Informatique et Libertés du 6 janvier 1978). Il peut l’exercer en s’adressant à Magievasion – 37 rue Jacques Forestier La Gaubretière (85130).
</p>

<p>
<b>Droits de propriété intellectuelle</b>
Les divers éléments de ce site web (la forme, la mise en page, le fonds, la structure...) sont protégés par le droit des dessins et modèles, le droit d’auteur, le droit des marques ainsi que le droit à l’image et ils ne peuvent être copiés ou imités en tout ou partie sauf autorisation expresse du directeur de publication. Toute personne ne respectant pas les dispositions légales applicables se rend coupable du délit de contrefaçon et est passible des sanctions pénales prévues par la loi. L’utilisation d’une œuvre protégée sans autorisation est considérée comme un acte de contrefaçon.
</p>

<p>
<b>Conditions d’utilisation du site internet www.illusio.show</b>
La poursuite de la navigation sur le site www.illusio.show (ci-après dénommé le « Site ») vaut acceptation sans réserve des dispositions et conditions d’utilisation qui suivent.
La version actuellement en ligne de ces conditions d’utilisation est la seule opposable pendant toute la durée d’utilisation du Site et jusqu’à ce qu’une nouvelle version la remplace.
</p>

<p>
<b>Accès au Site</b>
L’accès au Site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne pas utiliser ce Site et les informations ou données qui y  gurent à des  ns commerciales, politiques, publicitaires et pour toute forme de sollicitation commerciale et notamment l’envoi de courriers électroniques non sollicités. Toutes les informations pour accéder au Site seront diffusées sur les écrans du hall d’IMagic où se tient le spectacle ILLUSIO dans le Parc du Futuroscope. Un code, affecté à chaque spectacle, sera communiqué a n de pouvoir poster la photo grimace de l’enfant.
</p>

<p>
<b>Objet du Site</b>
Ce site est mis à disposition des personnes majeures venant assister au spectacle ILLUSIO présenté au Parc du Futuroscope a n de leur proposer de publier sur ce Site une photo de grimace de leurs enfants, en précisant leur prénom et leur âge. Les photos seront diffusées avant le spectacle sur les écrans du hall et dans la salle de spectacle. Ces photos seront ensuite sélectionnées pour n’en retenir que huit et les huit enfants sélectionnés participeront au spectacle ILLUSIO.
</p>

<p>
<b>Autorisation parentale</b>
En publiant les photos de leurs enfants sur le Site, les adultes autorisent l’Editeur à reproduire, dupliquer, enregistrer, diffuser au public, la photographie à titre gratuit, dans le cadre du spectacle Illusio sur les écrans du hall du Pavillon IMagic et dans la salle de spectacle. Cette cession des droits à l’image de l’enfant n’est valable que pendant la durée du spectacle dans le Pavillon IMagic pour lequel
le code a été affecté a n de poster
la photo. Les photos seront stockées pendant la durée du spectacle et seront toutes détruites au plus tard à la  n de spectacle ILLUSIO.
</p>

<p>
<b>Contenu du Site</b>
Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéos, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner ce Site et plus généralement tous les éléments reproduits ou utilisés sur le Site sont protégés par les lois en vigueur au titre de la propriété intellectuelle.
</p>
<p>
Ils sont la propriété pleine et entière de l’éditeur (Société MAGIEVASION PRODUCTIONS, SARL,
au capital de 7622 €,
dont le siège social est situé
37 rue Jacques Forestier
La Gaubretière (85130),
Tél. : 06 88 68 66 90
inscrite au Registre du Commerce
et des Sociétés de La Roche-Sur-Yon sous le N° 392 615 845).
Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l’accord préalable et écrit de l’éditeur et/ou du Parc du Futuroscope, sont strictement interdites. Le fait pour l’éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.
</p>

<p>
<b>Gestion du site</b>
Pour la bonne gestion du Site, l’éditeur pourra à tout moment :<br/>
• suspendre, interrompre ou de limiter l’accès à tout ou partie du Site, réserver l’accès au Site, ou à certaines parties du Site, à une catégorie déterminée de spectateurs.<br/>
• supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales.<br/>
• suspendre le Site a n de procéder à des mises à jour.
</p>

<p>
<b>Responsabilité</b>
La responsabilité de l’éditeur ne peut être engagée en cas de défaillance, panne, dif culté ou interruption
de fonctionnement, empêchant l’accès au Site ou à une de ses fonctionnalités. Le matériel de connexion au Site que vous utilisez est sous votre entière responsabilité. Vous devez prendre toutes les mesures appropriées pour protéger votre matériel et vos propres données notamment d’attaques virales par Internet. Vous êtes par ailleurs le seul responsable des sites et données que vous consultez. L’éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :<br/>
• du fait de l’usage du Site ou de tout service accessible via Internet ;<br/>
• du fait du non-respect par vous des présentes conditions générales.
</p>
<p>
L’éditeur n’est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation du Site et vous renoncez à toute action contre lui de ce fait.
</p>
<p>
Si l’éditeur venait à faire l’objet d’une procédure amiable ou judiciaire à raison de votre utilisation du Site, il pourra se retourner contre vous pour obtenir indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.
</p>

<p>
<b>Liens hypertextes</b>
Toute information accessible via un lien vers d’autres sites n’est pas sous le contrôle de l’éditeur qui décline toute responsabilité quant à leur contenu.
</p>

<p>
<b>Loi applicable</b>
Les présentes conditions d’utilisation du Site sont régies par la loi française et soumise à la compétence des tribunaux de La Roche-Sur-Yon.
</p>

<p>
<b>Contact</b>
Pour toute question, information sur les produits présentés sur le Site, ou concernant le Site lui-même, vous pouvez envoyer un mail à l’adresse suivante : magievasion@orange.fr
</p>
`,
}

const en: Dict = {
  introduction: {
    line1: 'Pull your most outrageous face',
    line2: 'Take a selfie',
    line3: 'Send in your photo!',
  },
  TakePicture: 'Take a picture',
  UploadInProgress: 'Upload in progress...',
  thanks: {
    line1: 'Thank you',
    line2: 'for your',
    line3: 'participation!',
  },
  firstName: 'First name',
  age: 'Age',
  yearOld: 'year old',
  showCode: 'Code on screens',
  ReadAndAccept: 'I have read and accepted the',
  GTU: 'GTU',
  send: 'Send',
  error: {
    noPreview: 'No photo loaded',
    imageResizer: 'Error while processing photo',
    onSend: 'Error while uploading photo',
    invalidFormat: 'Invalid image formal',
    invalidCode: 'Invalid code for the show',
    noSelect: 'No photo selected',
    multiSelect: 'Please select a single photo',
    onUpload: 'Error while sending data',
  },
  invalid: {
    firstName: 'What is his/her first name?',
    age: 'How old is he/she?',
    code: 'Copy the code displayed on the screens',
    acceptedConditions: 'Authorize usage of this photo',
  },
  'auth/in-progress': 'Connexion in progress...',
  'auth/invalid-email': 'Invalid email address',
  'auth/user-disabled': 'User disabled',
  'auth/user-not-found': 'User not found',
  'auth/wrong-password': 'Invalid code for this show',
  GTUTitle: 'TERMS OF USE',
  GTUContent: `
<p>
<b>PREAMBLE</b>
The photos that are sent are automatically deleted after each show!
</p>
<p>
<b>Éditeur</b>
Société MAGIEVASION PRODUCTIONS, SARL,
au capital de 7622 €,
dont le siège social est situé
37 rue Jacques Forestier
La Gaubretière (85130),
Tél : 06 88 68 66 90
inscrite au Registre du Commerce et des Sociétés de La Roche-Sur-Yon sous le N° 392 615 845,
n° de TVA Intracommunautaire FR 21 392615845
Courriel : magievasion@orange.fr
Directeur de la publication
Monsieur Bertran LOTTH, qualité d’artiste illusionniste
</p>

<p>
<b>Hébergement</b>
Google – Saint Ghislain, Belgique.
</p>

<p>
<b>Conception, création et développements</b>
Blue Source,
23 quai de Bondy à Lyon (69005). Courriel : contact@bluesource.fr
</p>

<p>
<b>Données personnelles</b>
Les informations que l’utilisateur communique sur le site permettent à la société MAGIEVASION, de traiter et sélectionner des photos de grimaces d’enfants venus assister au spectacle ILLUSIO présenté au Parc du Futuroscope a n de participer au spectacle. Conformément à l’article 32 de la Loi Informatique et Libertés du 6 janvier 1978, les informations indispensables au traitement et
à l’exécution des demandes sont signalées par un astérisque. Les autres informations demandées, pour lesquelles la réponse est facultative, sont destinées à mieux connaître les utilisateurs et ainsi à améliorer les services qui leur sont proposés. Conformément à la Loi Informatique et Libertés, l’utilisateur dispose d’un droit d’accès, de modi cation, de recti cation et de suppression des données qui le concernent (articles 39 et 40 de la Loi Informatique et Libertés du 6 janvier 1978). Il peut l’exercer en s’adressant à Magievasion – 37 rue Jacques Forestier La Gaubretière (85130).
</p>

<p>
<b>Droits de propriété intellectuelle</b>
Les divers éléments de ce site web (la forme, la mise en page, le fonds, la structure...) sont protégés par le droit des dessins et modèles, le droit d’auteur, le droit des marques ainsi que le droit à l’image et ils ne peuvent être copiés ou imités en tout ou partie sauf autorisation expresse du directeur de publication. Toute personne ne respectant pas les dispositions légales applicables se rend coupable du délit de contrefaçon et est passible des sanctions pénales prévues par la loi. L’utilisation d’une œuvre protégée sans autorisation est considérée comme un acte de contrefaçon.
</p>

<p>
<b>Conditions d’utilisation du site internet www.illusio.show</b>
La poursuite de la navigation sur le site www.illusio.show (ci-après dénommé le « Site ») vaut acceptation sans réserve des dispositions et conditions d’utilisation qui suivent.
La version actuellement en ligne de ces conditions d’utilisation est la seule opposable pendant toute la durée d’utilisation du Site et jusqu’à ce qu’une nouvelle version la remplace.
</p>

<p>
<b>Accès au Site</b>
L’accès au Site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne pas utiliser ce Site et les informations ou données qui y  gurent à des  ns commerciales, politiques, publicitaires et pour toute forme de sollicitation commerciale et notamment l’envoi de courriers électroniques non sollicités. Toutes les informations pour accéder au Site seront diffusées sur les écrans du hall d’IMagic où se tient le spectacle ILLUSIO dans le Parc du Futuroscope. Un code, affecté à chaque spectacle, sera communiqué a n de pouvoir poster la photo grimace de l’enfant.
</p>

<p>
<b>Objet du Site</b>
Ce site est mis à disposition des personnes majeures venant assister au spectacle ILLUSIO présenté au Parc du Futuroscope a n de leur proposer de publier sur ce Site une photo de grimace de leurs enfants, en précisant leur prénom et leur âge. Les photos seront diffusées avant le spectacle sur les écrans du hall et dans la salle de spectacle. Ces photos seront ensuite sélectionnées pour n’en retenir que huit et les huit enfants sélectionnés participeront au spectacle ILLUSIO.
</p>

<p>
<b>Autorisation parentale</b>
En publiant les photos de leurs enfants sur le Site, les adultes autorisent l’Editeur à reproduire, dupliquer, enregistrer, diffuser au public, la photographie à titre gratuit, dans le cadre du spectacle Illusio sur les écrans du hall du Pavillon IMagic et dans la salle de spectacle. Cette cession des droits à l’image de l’enfant n’est valable que pendant la durée du spectacle dans le Pavillon IMagic pour lequel
le code a été affecté a n de poster
la photo. Les photos seront stockées pendant la durée du spectacle et seront toutes détruites au plus tard à la  n de spectacle ILLUSIO.
</p>

<p>
<b>Contenu du Site</b>
Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéos, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner ce Site et plus généralement tous les éléments reproduits ou utilisés sur le Site sont protégés par les lois en vigueur au titre de la propriété intellectuelle.
</p>
<p>
Ils sont la propriété pleine et entière de l’éditeur (Société MAGIEVASION PRODUCTIONS, SARL,
au capital de 7622 €,
dont le siège social est situé
37 rue Jacques Forestier
La Gaubretière (85130),
Tél. : 06 88 68 66 90
inscrite au Registre du Commerce
et des Sociétés de La Roche-Sur-Yon sous le N° 392 615 845).
Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l’accord préalable et écrit de l’éditeur et/ou du Parc du Futuroscope, sont strictement interdites. Le fait pour l’éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.
</p>

<p>
<b>Gestion du site</b>
Pour la bonne gestion du Site, l’éditeur pourra à tout moment :<br/>
• suspendre, interrompre ou de limiter l’accès à tout ou partie du Site, réserver l’accès au Site, ou à certaines parties du Site, à une catégorie déterminée de spectateurs.<br/>
• supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales.<br/>
• suspendre le Site a n de procéder à des mises à jour.
</p>

<p>
<b>Responsabilité</b>
La responsabilité de l’éditeur ne peut être engagée en cas de défaillance, panne, dif culté ou interruption
de fonctionnement, empêchant l’accès au Site ou à une de ses fonctionnalités. Le matériel de connexion au Site que vous utilisez est sous votre entière responsabilité. Vous devez prendre toutes les mesures appropriées pour protéger votre matériel et vos propres données notamment d’attaques virales par Internet. Vous êtes par ailleurs le seul responsable des sites et données que vous consultez. L’éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :<br/>
• du fait de l’usage du Site ou de tout service accessible via Internet ;<br/>
• du fait du non-respect par vous des présentes conditions générales.
</p>
<p>
L’éditeur n’est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation du Site et vous renoncez à toute action contre lui de ce fait.
</p>
<p>
Si l’éditeur venait à faire l’objet d’une procédure amiable ou judiciaire à raison de votre utilisation du Site, il pourra se retourner contre vous pour obtenir indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.
</p>

<p>
<b>Liens hypertextes</b>
Toute information accessible via un lien vers d’autres sites n’est pas sous le contrôle de l’éditeur qui décline toute responsabilité quant à leur contenu.
</p>

<p>
<b>Loi applicable</b>
Les présentes conditions d’utilisation du Site sont régies par la loi française et soumise à la compétence des tribunaux de La Roche-Sur-Yon.
</p>

<p>
<b>Contact</b>
Pour toute question, information sur les produits présentés sur le Site, ou concernant le Site lui-même, vous pouvez envoyer un mail à l’adresse suivante : magievasion@orange.fr
</p>
`,
}

const dicts: { [Locale]: Dict } = { fr, en }

let currentLocale: Locale = 'fr' // default locale

export function getLocale(): Locale {
  return currentLocale
}

export function setLocale(newLocale: Locale) {
  currentLocale = newLocale
}

export const locales: Array<Locale> = ['fr', 'en']

const tr = (): Dict => dicts[currentLocale]

export default tr
