// @flow

import React from 'react'

import './Photo.css'

const FULL_HD_ASPECT_RATIO = 1920 / 1080

export function fullHDStyle(height: number) {
  return { height, width: height / FULL_HD_ASPECT_RATIO }
}

type Props = {
  src: string,
  srcWidth: number,
  srcHeight: number,
  height: number,
}

const Photo = ({ src, srcWidth, srcHeight, height }: Props) => {
  const divStyle = fullHDStyle(height)

  const width = (height * srcWidth) / srcHeight
  const imgStyle = { width, height }

  return (
    <div className="photoContainer" style={divStyle}>
      <img className="photoImage" src={src} style={imgStyle} alt="" />
    </div>
  )

  // TODO special flag
  //     newImg.onload = () => {
  //     // no longer need to read the blob so it's revoked
  //     URL.revokeObjectURL(url)
  //   }
}

export default Photo
