// @flow

// import firebase from './firebase'
import { log } from './log'

window.addEventListener('error', e => {
  const { stack } = e.error
  let message = e.error.toString()
  if (stack) {
    message += `\n${stack}`
  }
  log(message)
})

/* disable connection watcher
if (APP !== 'Mobile') {
  let connectionWarmingUp = true
  
  const connectedRef = firebase.database().ref('.info/connected')
  connectedRef.on('value', snap => {
    if (connectionWarmingUp) return

    if (snap.val() === true) {
      debug('Connected')
    } else {
      debug('Connection lost')
    }
  })

  // Wait for 2 seconds for connection to be stable
  setTimeout(() => {
    connectionWarmingUp = false
    connectedRef.once('value', snap => {
      if (snap.val() !== true) {
        debug('No connection on start')
      }
    })
  }, 2000)
}
*/
