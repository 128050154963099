// @flow

import React, { Component } from 'react'

import camera from './icons/camera.svg'
import welcomeFr from './images/welcome.fr.svg'
import welcomeEn from './images/welcome.en.svg'
import LanguageSwitcher from './LanguageSwitcher'
import tr, { getLocale } from './Translator'

import type { Locale } from './Translator'

import './Welcome.css'

const welcome: { [Locale]: string } = { fr: welcomeFr, en: welcomeEn }

type Props = {
  onImageChanged: (SyntheticEvent<HTMLInputElement>) => void,
}

class Welcome extends Component<Props> {
  render() {
    return (
      <div className="welcome">
        <img
          src={welcome[getLocale()]}
          className="participezA"
          alt="Participez à Illusio"
        />
        <ol className="instructions">
          <li>{tr().introduction.line1}</li>
          <li>{tr().introduction.line2}</li>
          <li>{tr().introduction.line3}</li>
        </ol>
        <div className="fullWidthRow">
          <LanguageSwitcher
            locale="fr"
            onLanguageChanged={() => this.forceUpdate()}
          />
          <label className="file-upload" htmlFor="imageInput">
            <input
              type="file"
              id="imageInput"
              accept="image/*"
              capture="camera"
              onChange={e => this.props.onImageChanged(e)}
            />
            <img className="camera" src={camera} alt={tr().TakePicture} />
          </label>
          <LanguageSwitcher
            locale="en"
            onLanguageChanged={() => this.forceUpdate()}
          />
        </div>
      </div>
    )
  }
}

export default Welcome
