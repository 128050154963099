// @flow

import React from 'react'
import type { Element, ChildrenArray } from 'react'
import './Modal.css'

type Props = {
  id: string,
  children: ChildrenArray<Element<any>>,
}

const Modal = ({ id, children }: Props) => {
  const closeModal = () => {
    window.location = '#nomodal'
  }

  return (
    <div
      id={id}
      className="modalDialog"
      role="button"
      tabIndex="0"
      onClick={closeModal}
      onKeyPress={closeModal}
    >
      <div>
        <a href="#nomodal" title="Fermer" className="modalCloseButton">
          ✕
        </a>
        <div className="modalContent">{children}</div>
      </div>
    </div>
  )
}

export default Modal
