// @flow

import { log } from './log'
import firebase from './firebase'

class Storage {
  // eslint-disable-next-line no-undef
  static newKey(database: $npm$firebase$database$Reference): string {
    const { key } = database.push()
    if (key == null) {
      log('Null key when pushing to database')
      return `GENERATED-KEY ${new Date().toLocaleDateString()}`
    }
    return key
  }

  static upload(ref: string, file: Blob, name: string): Promise<string> {
    const metadata = {
      cacheControl: 'public,max-age=31536000',
    }

    const fileRef = firebase
      .storage()
      .ref(ref)
      .child(name)

    return new Promise((resolve, reject) => {
      fileRef.put(file, metadata).then(
        snapshot => {
          // Upload completed successfully, now we can get the download URL
          return snapshot.ref
            .getDownloadURL()
            .then(downloadUrl => resolve(downloadUrl))
            .catch(error => {
              log(
                'getDownloadURL failed after upload',
                name,
                error.code,
                error.message,
              )
              Storage.delete(ref, name)
              reject('onUpload')
            })
        },
        error => {
          log('Image storage upload error', name, error.name, error.message)
          Storage.delete(ref, name)
          reject('onUpload')
        },
      )
    })
  }

  static delete(ref: string, name: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const fileRef = firebase
        .storage()
        .ref(ref)
        .child(name)

      fileRef
        .delete()
        .then(() => {
          resolve()
        })
        .catch(error => {
          log('Unable to delete image from storage', ref, name, error.code)
          reject()
        })
    })
  }
}

export default Storage
