// @flow

import firebase from './firebase'

export const APP = 'Mobile'

export const log = (...args: mixed[]) => {
  const message = args.join(' ')
  // eslint-disable-next-line no-console
  console.warn(`LOG ${message}`)

  if (process.env.NODE_ENV === 'production') {
    firebase
      .database()
      .ref(`logs/${APP}`)
      .push({
        message,
        userAgent: window.navigator.userAgent,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
      })
  }
}

export const debug = (...args: mixed[]) => {
  const message = [new Date().toISOString(), args.join(' ')].join(' ')
  // eslint-disable-next-line no-console
  console.log(`DEBUG ${message}`)
  if (process.env.NODE_ENV === 'production') {
    firebase
      .database()
      .ref(`debugs/${APP}`)
      .push(message)
  }
}
